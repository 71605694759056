// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_ahqjg{display:flex;flex-direction:column;height:100%;justify-content:space-between}.filters_FdNC\\+{align-items:flex-end;display:flex;gap:1rem}.sticky_8HPYL{bottom:1rem;display:flex;flex-direction:row;margin:0 auto;min-width:20rem;position:sticky;width:-moz-fit-content;width:fit-content;z-index:1}.filterMenu_DakbF{display:flex;flex-direction:column}.headerRight_Pfabo{flex:1}.filterToggleItem_5BLgL{cursor:pointer;padding:.5rem}.filterToggleItem_5BLgL:hover{background-color:rgba(0,0,0,.2)}.flexCell_F3UTg{display:flex;gap:.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_ahqjg",
	"filters": "filters_FdNC+",
	"sticky": "sticky_8HPYL",
	"filterMenu": "filterMenu_DakbF",
	"headerRight": "headerRight_Pfabo",
	"filterToggleItem": "filterToggleItem_5BLgL",
	"flexCell": "flexCell_F3UTg"
};
module.exports = ___CSS_LOADER_EXPORT___;
